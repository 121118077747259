import React from "react"

// Libraries
import styled from "styled-components"
import { Trans } from "gatsby-plugin-react-i18next"

// Utils
import breakpoints from "utils/breakpoints"

// Components
import Section from "components/case-study-section"

// Post-its
import PostItOne from "assets/icons/case-studies/arena/post-it-one.inline.svg"
import PostItTwo from "assets/icons/case-studies/arena/post-it-two.inline.svg"
import PostItThree from "assets/icons/case-studies/arena/post-it-three.inline.svg"
import PostItFour from "assets/icons/case-studies/arena/post-it-four.inline.svg"

const StyledTheChallange = styled(Section)`
  overflow-y: hidden;
  padding: 56px 0;

  ${breakpoints.medium`
  padding: 120px 0:
  `}

  .section__content {
    margin-bottom: 56px;

    ${breakpoints.medium`
    margin-bottom: 92px;
    `}
  }
`

const PostItWrapper = styled.div`
  flex-shrink: 0;
  display: flex;
  justify-content: space-between;
  overflow-x: auto;

  ${breakpoints.small`
    width: 100%;
  `}

  ${breakpoints.medium`
    width: auto;
    justify-content: center;
  `}

  svg {
    flex-shrink: 0;
    margin-right: 8px;

    &:last-child {
      margin-right: 0;
    }

    ${breakpoints.small`
      flex-shrink: 1;
    `}

    ${breakpoints.medium`
      width: 224px;
      height: auto;
    `}
  }
`

const TheChallange = () => (
  <StyledTheChallange>
    <div className="container">
      <div className="section__title">
        <div className="sticky">
          <p>
            <Trans>The Challenge</Trans>
          </p>
        </div>
      </div>

      <div className="section__content">
        <h3 className="color--midnight">
          <Trans>
            How can we revamp Arena’s traditional look to match their
            revolutionary offering, applying it across the brand & products?
          </Trans>
        </h3>
        <p className="color--midnight mt-4">
          <Trans>
            Arena’s SaaS offering is as cutting-edge as they come: Predictions
            generated by artificial intelligence that tell companies whether a
            candidate for a job will be retained if hired. Not only that—Arena’s
            algorithm also includes powerful bias mitigation technology. This
            means that companies that hire based on Arena’s predictions remove
            any sort of unconscious prejudice or bias from the hiring process,
            making for a more equitable job market. When they partened with us,
            Arena had a classic, traditional look that needed a refesh that
            truly reflected the way they’re transforming the labor market using
            AI.
          </Trans>
          <br />
          <br />
          <Trans>
            From the start, we were excited to take on a project whose value’s
            aligned so closely with Indi’s. But that doesn’t mean it didn’t come
            without its challenges. Over our more than 2-year partnership, we
            identified 4 main challenges to elevating Arena’s digital brand:
          </Trans>
        </p>
      </div>

      <div className="row justify-content-center">
        <div className="col-12 col-md-10">
          <div className="post-it-wrapper">
            <PostItWrapper>
              <PostItOne />
              <PostItTwo />
              <PostItThree />
              <PostItFour />
            </PostItWrapper>
          </div>
        </div>
      </div>
    </div>
  </StyledTheChallange>
)

export default TheChallange
