import React from "react"
import { useStaticQuery, graphql } from "gatsby"
import Img from "gatsby-image"

// Component
import FeaturedImage from "components/case-study-featured-image/"

export default () => {
  const data = useStaticQuery(graphql`
    query {
      file(relativePath: { eq: "case-studies/arena/featured-image.png" }) {
        childImageSharp {
          fluid(maxWidth: 2240, quality: 100) {
            ...GatsbyImageSharpFluid_withWebp
          }
        }
      }
    }
  `)

  return (
    <FeaturedImage>
      <Img
        fluid={data.file.childImageSharp.fluid}
        resolutions={data.file.childImageSharp.resolutions}
        alt="Arena"
      />
    </FeaturedImage>
  )
}