import React from "react"

// Libraries
import styled from "styled-components"
import { Trans } from "gatsby-plugin-react-i18next"

// Utils
import breakpoints from "utils/breakpoints"

// Components
import Section from "components/case-study-section"
import LottieObserved from "components/lottie-observed/"

// Animation
import ArenaAnimation from "assets/animations/case-studies/arena/arena-animation.json"

const StyledTheProject = styled(Section)`
  padding-bottom: 0 !important;
  overflow-y: hidden;

  .section__content {
    margin-bottom: 56px;

    ${breakpoints.medium`
      margin-bottom: 80px;
    `}

    &.last {
      margin: 0;
    }
  }
`

const TheProject = () => (
  <StyledTheProject>
    <div className="container">
      <div className="section__title">
        <div className="sticky">
          <p>
            <Trans>The Project</Trans>
          </p>
        </div>
      </div>

      <div className="section__content">
        <p className="color--midnight">
          <Trans>
            What began as an “express” rebranding for Arena Analytics became a
            2-year long partnership, during which our team created elevated,
            cohesive branding to implement throughout the company. The outcome?
            A new website and aesthetic to implement across Arena’s branded
            materials, from product demos to marketing materials. The cherry on
            top? A deep understanding of our client’s business, mission, and
            values that helped us create the most brand-aligned designs
            possible.
          </Trans>
        </p>
      </div>
    </div>
    <div className="container d-flex justify-content-end">
      <div className="section__content last">
        <LottieObserved animation={ArenaAnimation} loop />
      </div>
    </div>
  </StyledTheProject>
)

export default TheProject
