import React from "react"
import { useStaticQuery, graphql } from "gatsby"
import Img from "gatsby-image"
import styled from "styled-components"

// Utils
import breakpoint from "utils/breakpoints/"
import { colors } from "utils/variables/"

// Libraries
import { Trans } from "gatsby-plugin-react-i18next"

// Components
import Section from "components/case-study-section/"
import Carousel from "components/case-study-carousel/"

const StyledSection = styled(Section)`
  padding-top: 56px !important;
  background-color: ${colors.white};

  ${breakpoint.medium`
    padding-top: 120px!important;
  `}
`

const StyledCarousel = styled(Carousel)`
  margin-top: 40px;

  ${breakpoint.medium`
    margin-top: 80px;
  `}
`

const TheResult = () => {
  const data = useStaticQuery(graphql`
    query {
      carouselImageOne: file(
        relativePath: { eq: "case-studies/arena/cs-arena-image1.png" }
      ) {
        childImageSharp {
          fluid(maxWidth: 928, quality: 100) {
            ...GatsbyImageSharpFluid_withWebp
          }
        }
      }

      carouselImageTwo: file(
        relativePath: { eq: "case-studies/arena/cs-arena-image2.png" }
      ) {
        childImageSharp {
          fluid(maxWidth: 928, quality: 100) {
            ...GatsbyImageSharpFluid_withWebp
          }
        }
      }

      carouselImageThree: file(
        relativePath: { eq: "case-studies/arena/cs-arena-image3.png" }
      ) {
        childImageSharp {
          fluid(maxWidth: 928, quality: 100) {
            ...GatsbyImageSharpFluid_withWebp
          }
        }
      }

      carouselImageFour: file(
        relativePath: { eq: "case-studies/arena/cs-arena-image4.png" }
      ) {
        childImageSharp {
          fluid(maxWidth: 928, quality: 100) {
            ...GatsbyImageSharpFluid_withWebp
          }
        }
      }

      carouselImageFive: file(
        relativePath: { eq: "case-studies/arena/cs-arena-image5.png" }
      ) {
        childImageSharp {
          fluid(maxWidth: 928, quality: 100) {
            ...GatsbyImageSharpFluid_withWebp
          }
        }
      }
    }
  `)

  return (
    <StyledSection className="bg--cream">
      <div className="container">
        <div className="section__title">
          <div className="sticky">
            <p>
              <Trans>The results</Trans>
            </p>
          </div>
        </div>

        <div className="section__content">
          <h3>
            <Trans>
              The outcomes of this long-term partnership are too many to count.
              But to name just a few:
            </Trans>
          </h3>
          <br />
          <p>
            <Trans>
              1- A new, sophisticated brand identity implemented across Arena’s
              company and teams
            </Trans>
          </p>
          <br />
          <p>
            <Trans>
              2- Product UX/UI that’s both elegant and usable for all audiences,
              along with demos to show it off
            </Trans>
          </p>
          <br />
          <p>
            <Trans>
              3- A website with all the bells and whistles, including motion UI
              on custom graphics to help the audience fully understand what
              Arena is and why it’s the most high-tech tool out there
            </Trans>
          </p>
          <br />
          <p>
            <Trans>
              Our work with Arena is a great testament to the fact that taking
              the time to connect, deeply understand your client’s offering, and
              build together pays off.
            </Trans>
          </p>
        </div>
      </div>

      <StyledCarousel id="resultsCarousel">
        <div data-slide className="slide align--with-title">
          <Img
            fluid={data.carouselImageOne.childImageSharp.fluid}
            alt="Arena"
          />
        </div>
        <div data-slide className="slide">
          <Img
            fluid={data.carouselImageTwo.childImageSharp.fluid}
            alt="Arena"
          />
        </div>
        <div data-slide className="slide">
          <Img
            fluid={data.carouselImageThree.childImageSharp.fluid}
            alt="Arena"
          />
        </div>
        <div data-slide className="slide">
          <Img
            fluid={data.carouselImageFour.childImageSharp.fluid}
            alt="Arena"
          />
        </div>
        <div data-slide className="slide">
          <Img
            fluid={data.carouselImageFive.childImageSharp.fluid}
            alt="Arena"
          />
        </div>
      </StyledCarousel>
    </StyledSection>
  )
}

export default TheResult
