import React from "react"

// Libraries
import { graphql } from "gatsby"
import { useTranslation } from "gatsby-plugin-react-i18next"

// Utils
import { colors } from "utils/variables/"
import StrategyAndStructure from "sections/case-studies/arena/strategy-and-structure"

// Layout
import Layout from "layouts/layout-primary"

// Components
import ContextConsumer from "components/context/"
import Seo from "components/seo/"
import Cta from "components/case-study-call-to-action/"
import NextProject from "components/case-study-next-project/"
import NextProjectHero from "sections/case-studies/kapital/hero"
import DesignAndDevelopment from "sections/case-studies/arena/design-and-development"
import OpenGraph from "assets/images/case-studies/arena/open-graph.png"

// Sections
import Hero from "sections/case-studies/arena/hero"
import TheProject from "sections/case-studies/arena/the-project"
import TheChallange from "sections/case-studies/arena/the-challange"
import VisualBranding from "sections/case-studies/arena/visual-branding"
import UxUiProductDesign from "sections/case-studies/arena/ux-ui-product-design"
import TheResults from "sections/case-studies/arena/the-result"
import TheSolution from "sections/case-studies/arena/the-solution"
import MarketingMaterial from "sections/case-studies/arena/marketing-material"
import SalesDemoDesign from "sections/case-studies/arena/sales-demo-design"

const Arena = props => {
  const { t } = useTranslation()
  return (
    <Layout menuColor={colors.supernova}>
      <ContextConsumer>
        {({ data, set }) => {
          if (data.useYellowFooter) {
            set({ useYellowFooter: false })
          }
          if (!data.darkMenu) {
            set({ darkMenu: true })
          }
          if (data.showFooter) {
            set({ showFooter: false })
          }
          if (data.simplifiedFooter) {
            set({ simplifiedFooter: false })
          }
        }}
      </ContextConsumer>
      <Seo
        title={t("Arena Analytics Case Study - Indicius Design Agency")}
        image={OpenGraph}
        description={t(
          "See our work with Arena, an AI hiring tool whose branding and website we overhauled to match their innovative product."
        )}
      />
      <Hero />
      <TheProject />
      <TheChallange />
      <TheSolution />
      <VisualBranding />
      <UxUiProductDesign />
      <SalesDemoDesign />
      <StrategyAndStructure />
      <DesignAndDevelopment />
      <MarketingMaterial />
      <TheResults />
      <Cta
        title={t(
          "Do you need to revamp the UX/UI of an already popular App, and really stand out?"
        )}
      />
      <NextProject url="/work/hpims" nextProjectHero={<NextProjectHero />} />
    </Layout>
  )
}

export const query = graphql`
  query($language: String!) {
    locales: allLocale(filter: { language: { eq: $language } }) {
      edges {
        node {
          ns
          data
          language
        }
      }
    }
  }
`

export default Arena
