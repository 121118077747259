import React from "react"
import styled from "styled-components"

// Libraries
import { Trans } from "gatsby-plugin-react-i18next"
import { useStaticQuery, graphql } from "gatsby"
import Img from "gatsby-image"

// Utils
import breakpoint from "utils/breakpoints/"

// Components
import Section from "components/case-study-section/"

const StyledSection = styled(Section)`
  padding-bottom: 0 !important;

  .title-product {
    font-size: 28px;
    line-height: ;
  }
`

const ImageWrapper = styled.div`
  max-width: 1440px;
  margin: 16px auto 0 auto;
  overflow: hidden;
  padding-bottom: 85px;

  ${breakpoint.medium`
    margin-top: 60px;
    padding-bottom: 120px;
  `}

  .image {
    width: 95%;
    max-width: 1280px;
    display: block;
    margin-bottom: 16px;

    &:nth-child(even) {
      margin-left: auto;
    }

    ${breakpoint.medium`
      margin-bottom: 20px;

      &:last-child {
        margin-bottom: 0;
      }
    `}
  }
`

const TheSolution = () => {
  const data = useStaticQuery(graphql`
    query {
      imageOne: file(
        relativePath: {
          eq: "case-studies/arena/cs-arena-the-solution-image-1.png"
        }
      ) {
        childImageSharp {
          fluid(maxWidth: 1280, quality: 100) {
            ...GatsbyImageSharpFluid_withWebp
          }
        }
      }
      imageTwo: file(
        relativePath: {
          eq: "case-studies/arena/cs-arena-the-solution-image-2.png"
        }
      ) {
        childImageSharp {
          fluid(maxWidth: 1280, quality: 100) {
            ...GatsbyImageSharpFluid_withWebp
          }
        }
      }
    }
  `)

  return (
    <StyledSection className="bg--cream">
      <div className="container">
        <div className="section__title">
          <div className="sticky">
            <p>
              <Trans>The solution</Trans>
            </p>
          </div>
        </div>

        <div className="section__content">
          <h3>
            <Trans>
              Our project with Arena grew and evolved over our time working
              together, but throughout we worked on product, marketing, and
              website projects. Here’s an overview of the innovative solutions
              we worked on for each one.
            </Trans>
          </h3>
          <br />
          <h3 className="font--weight-700 mb-3 title-product">
            <Trans>Product</Trans>
          </h3>
          <p className="title">
            <Trans>1. Brand Narrative Workshop</Trans>
          </p>
          <p>
            <Trans>
              First things first: Defining Arena’s new identity. Using our
              custom Brand Narrative Workshop, we were able to nail down the
              story that Arena wanted to tell the world. We created a Brand
              Messaging Guide based on this workshop that included voice and
              tone guidelines, language to resonate with Arena’s different
              audiences, and Voice of Customer research.
            </Trans>
          </p>
          <br />
        </div>
      </div>
      <ImageWrapper>
        <div className="image" data-aos="fade-left" data-aos-duration="600">
          <Img
            fluid={data.imageOne.childImageSharp.fluid}
            alt="Illustration System"
            style={{ width: "100%" }}
          />
        </div>
        <div className="image" data-aos="fade-right" data-aos-duration="600">
          <Img
            fluid={data.imageTwo.childImageSharp.fluid}
            alt="Illustration System"
            style={{ width: "100%" }}
          />
        </div>
      </ImageWrapper>
    </StyledSection>
  )
}

export default TheSolution
