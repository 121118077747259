import React from "react"
import styled from "styled-components"

// Components
import CaseStudyHero from "components/case-study-hero/"
import FeaturedImage from "./featured-image"

// Libraries
import { useTranslation } from "gatsby-plugin-react-i18next"

// Utils
import { colors } from "utils/variables"
import breakpoints from "utils/breakpoints"

// Icons
import IconLeft from "assets/icons/case-studies/arena/icon-hero-left.inline.svg"
import IconRight from "assets/icons/case-studies/arena/icon-hero-right.inline.svg"

const StyledIcons = styled.div`
  position: relative;
  z-index: -1;

  .left__icon {
    position: absolute;
    left: 0;
    top: -430px;
    display: none;

    ${breakpoints.medium`
    display: block;
    `}
  }

  .right__icon {
    position: absolute;
    right: -16px;
    top: -472px;
    display: none;

    ${breakpoints.medium`
    display: block;
    `}
  }
`

const Hero = () => {
  const { t } = useTranslation()
  return (
    <React.Fragment>
      <CaseStudyHero
        title={t(
          "How a long-term partnership keeps elevating an AI hiring tool"
        )}
        details={[t("Arena Analytics"), "Baltimore, USA", "2021-2022"]}
        tags={[
          t("Brand Narrative Workshop"),
          t("Visual Branding"),
          t("Product Design"),
          t("Website Design & Development"),
          t("Marketing Material Design"),
        ]}
        backgroundColor={colors.supernova}
        color={colors.midnight}
        bulletColor={colors.indiblue}
        width={"736px"}
      />
      <FeaturedImage />
      <StyledIcons>
        <IconLeft className="left__icon" />
        <IconRight className="right__icon" />
      </StyledIcons>
    </React.Fragment>
  )
}

export default Hero
