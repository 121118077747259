import React from "react"
import styled from "styled-components"

// Libraries
import { Trans } from "gatsby-plugin-react-i18next"

// Components
import Section from "components/case-study-section/"

// Utils
import { colors } from "utils/variables"

// Images
import DesignAndDevelopmentDesktop from "assets/images/case-studies/arena/design-and-development-desktop.inline.svg"
import DesignAndDevelopmentMobile from "assets/images/case-studies/arena/design-and-development-mobile.svg"

// Utils
import breakpoints from "utils/breakpoints"

const StyledDesignAndDevelopment = styled(Section)`
  padding-bottom: 0 !important;
  background-color: ${colors.cream};

  .image-wrapper {
    padding-right: 16px;
    margin-top: 56px;

    ${breakpoints.small`
      margin-top: 160px;
    `}
  }
`

const DesignAndDevelopment = () => (
  <StyledDesignAndDevelopment>
    <div className="container justify-content-end">
      <div className="section__content">
        <p className="title color--midnight">
          <Trans>2. Web Design & Development</Trans>
        </p>
        <p className="color--midnight">
          <Trans>
            Once we had the Sitemap defined, we got designing and developing the
            first round of pages that the Arena team marked as priorities for
            release on the web. This would help us get the new site online ASAP
            as we kept on working on lower-priority pages. As the Arena team
            provided us content and input on the layout for each page, we built
            wireframes for each one and applied the new style when approved.
          </Trans>
        </p>
        <br />
        <p className="color--midnight">
          <Trans>
            Another essential part of this phase of the project was to create
            graphics and illustrations that would accurately, but simply reflect
            Arena’s complex technology. To accomplish this, we created graphics
            featuring simplified versions of product UI and animated
            illustrations that represented the technology in a more abstract
            fashion. As the icing on the cake, we designed a custom icon system
            that the team can use now and in the future to represent their
            offerings.
          </Trans>
        </p>
        <br />
        <p className="color--midnight">
          <Trans>
            Our dev team made sure that Arena was set up for independence by
            building the pages that would be most often updated (blog, events)
            on a CMS and teaching them how to use it. We also built them modules
            that would allow them to set up and launch their own landing pages
            whenever necessary.
          </Trans>
        </p>
      </div>
    </div>

    <div className="image-wrapper d-flex justify-content-center">
      <DesignAndDevelopmentDesktop className="d-none d-sm-block" />
      {/* <DesignAndDevelopmentMobile className="d-block d-sm-none" /> */}
      <img
        src={DesignAndDevelopmentMobile}
        alt=""
        className="d-block d-sm-none"
      />
    </div>
  </StyledDesignAndDevelopment>
)

export default DesignAndDevelopment
