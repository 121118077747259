import React from "react"
import styled from "styled-components"

// Libraries
import { Trans } from "gatsby-plugin-react-i18next"

// Components
import Section from "components/case-study-section/"

// Images
import StrategyAndStructureDesktop from "assets/images/case-studies/arena/strategy-and-structure-desktop.inline.svg"
import StrategyAndStructureMobile from "assets/images/case-studies/arena/strategy-and-structure-mobile.inline.svg"

// Utils
import breakpoints from "utils/breakpoints"

const StyledStrategyAndStructure = styled(Section)`
  padding: 56px 0;

  ${breakpoints.small`
  padding: 160px 0;
`}

  .image-wrapper {
    margin-top: 56px;

    ${breakpoints.small`
    margin-top: 160px;
    `}
  }
`

const StrategyAndStructure = () => (
  <StyledStrategyAndStructure>
    <div className="container justify-content-end">
      <div className="section__content">
        <h3 className="color--midnight mb-3">
          <Trans>Website</Trans>
        </h3>
        <p className="title color--midnight">
          <Trans>1. Strategy & Structure Discovery</Trans>
        </p>
        <p className="color--midnight">
          <Trans>
            We were SO pumped when after over a year working together, the Arena
            team took the plunge and asked us to fully redesign their website.
            And we didn’t take the request lightly—We had worked hard to deeply
            understand Arena’s offering and we wanted to make sure we could help
            their audience do the same.
          </Trans>
        </p>
        <br />
        <p className="color--midnight">
          <Trans>
            As a starting point for our website design, we took a deep dive into
            Arena’s strategy for the site–Where their business and user goals
            overlap. We then created user navigation paths based on the user
            goals we defined with the Arena team, simulating how users with
            different goals would move through the site. This process led us to
            a Sitemap that wasn’t simply created off the top of our heads, but
            rather built from a foundation of user goals and business strategy.
          </Trans>
        </p>
      </div>
    </div>

    <div className="image-wrapper d-flex justify-content-center">
      <StrategyAndStructureDesktop className="d-none d-sm-block" />
      <StrategyAndStructureMobile className="d-block d-sm-none" />
    </div>
  </StyledStrategyAndStructure>
)

export default StrategyAndStructure
