import React from "react"
import styled from "styled-components"
// Libraries
import { Trans } from "gatsby-plugin-react-i18next"

// Components
import Section from "components/case-study-section/"

// Images
import VisualBrandingDesktop from "assets/images/case-studies/arena/visual-branding-desktop.inline.svg"
import VisualBrandingMobile from "assets/images/case-studies/arena/visual-branding-mobile.inline.svg"

// Utils
import breakpoints from "utils/breakpoints"

const StyledVisualBranding = styled(Section)`
  padding: 0;
  
  .image-wrapper {
    margin-top: 56px;
    display: flex;
    justify-content: center;

    ${breakpoints.medium`
      margin-top: 160px;
    `}
  }
`

const VisualBranding = () => (
  <StyledVisualBranding>
    <div className="container justify-content-end">
      <div className="section__content">
        <p className="title color--midnight">
          <Trans>2. Visual Branding</Trans>
        </p>
        <p className="color--midnight">
          <Trans>
            In our branding workshop we defined Arena as the trailblazer of
            hiring tools, transforming the labor marketing by cutting
            uncertainty and bias out of the hiring process. This was reflected
            in Arena’s new Style Tile, which included an exciting new color
            palette and human-centered imagery that reflected Arena’s values.
            This formed the basis for a UI Library that kept on growing as we
            went along.
          </Trans>
        </p>
      </div>
    </div>
    <div className="image-wrapper d-flex justify-content-center">
      <VisualBrandingDesktop className="d-none d-sm-block" />
      <VisualBrandingMobile className="d-block d-sm-none" />
    </div>{" "}
  </StyledVisualBranding>
)

export default VisualBranding
