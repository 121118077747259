import React from "react"

// Libraries
import styled from "styled-components"
import { useStaticQuery, graphql } from "gatsby"
import Img from "gatsby-image"
import { Trans } from "gatsby-plugin-react-i18next"

// Utils
import breakpoint from "utils/breakpoints/"

// Components
import Section from "components/case-study-section/"

const StyledSection = styled(Section)`
  padding-bottom: 0 !important;
  
  .section__animation {
    margin: 32px 0;

    ${breakpoint.medium`
      margin: 80px 0;
    `}
  }

  .image-section {
    margin-top: 56px;
    padding: 0 16px;

    ${breakpoint.medium`
      margin-top: 160px;
    `}

    .image-wrapper {
      margin: 0 auto 16px auto;

      &--mobile {
        ${breakpoint.small`
        display: none!important;
      `}
      }

      &--desktop {
        display: none !important;

        ${breakpoint.small`
        display: block !important;
      `}
      }
    }
  }
`

const MarketingMaterial = () => {
  const {
    imageMobile,
    imageDesktop,
    imageMobile_2,
    imageMobile_3,
  } = useStaticQuery(graphql`
    query {
      imageMobile: file(
        relativePath: {
          eq: "case-studies/arena/arena-cs-marketing-mobile-1.png"
        }
      ) {
        childImageSharp {
          fluid(maxWidth: 560, quality: 100) {
            ...GatsbyImageSharpFluid_withWebp
          }
        }
      }
      imageMobile_2: file(
        relativePath: {
          eq: "case-studies/arena/arena-cs-marketing-mobile-2.png"
        }
      ) {
        childImageSharp {
          fluid(maxWidth: 560, quality: 100) {
            ...GatsbyImageSharpFluid_withWebp
          }
        }
      }
      imageMobile_3: file(
        relativePath: {
          eq: "case-studies/arena/arena-cs-marketing-mobile-3.png"
        }
      ) {
        childImageSharp {
          fluid(maxWidth: 560, quality: 100) {
            ...GatsbyImageSharpFluid_withWebp
          }
        }
      }
      imageMobile_4: file(
        relativePath: {
          eq: "case-studies/arena/arena-cs-marketing-mobile-4.png"
        }
      ) {
        childImageSharp {
          fluid(maxWidth: 560, quality: 100) {
            ...GatsbyImageSharpFluid_withWebp
          }
        }
      }
      imageDesktop: file(
        relativePath: {
          eq: "case-studies/arena/cs-arena-marketing-image-desktop.png"
        }
      ) {
        childImageSharp {
          fluid(maxWidth: 1440, quality: 100) {
            ...GatsbyImageSharpFluid_withWebp
          }
        }
      }
    }
  `)
  return (
    <StyledSection>
      <div className="container">
        <div className="section__content ">
          <h3 className="mb-3">Marketing</h3>
          <p className="title mb-4">
            <Trans>1. Marketing Materials & Assets Design</Trans>
          </p>
          <p>
            <Trans>
              A constant throughout much of our partnership with Arena was our
              work with the Marketing team. This included work on a lot of
              classic marketing assets—Signage, ads, slide decks, step and
              repeats, one-pagers, and brochures—but also creating templates
              that would allow the team to be more autonomous going forward. Now
              a standardized slide, newsletter, letter, envelope, case study,
              and one-pager is used across the company to ensure brand
              consistency.
            </Trans>
          </p>
        </div>
      </div>

      <div className="image-section">
        <div>
          <Img
            className="image-wrapper image-wrapper--mobile"
            fluid={imageMobile.childImageSharp.fluid}
            style={{ width: "100%", display: "block" }}
            alt="STYLE TILE EXPLORATIONS"
          />
          <Img
            className="image-wrapper image-wrapper--mobile"
            fluid={imageMobile_2.childImageSharp.fluid}
            style={{ width: "100%", display: "block" }}
            alt="STYLE TILE EXPLORATIONS"
          />
          <Img
            className="image-wrapper image-wrapper--mobile"
            fluid={imageMobile_3.childImageSharp.fluid}
            style={{ width: "100%", display: "block" }}
            alt="STYLE TILE EXPLORATIONS"
          />
        </div>

        <Img
          className="image-wrapper image-wrapper--desktop"
          fluid={imageDesktop.childImageSharp.fluid}
          style={{
            maxWidth: "1440px",
            width: "100%",
            display: "block",
          }}
          alt="STYLE TILE EXPLORATIONS"
        />
      </div>
    </StyledSection>
  )
}

export default MarketingMaterial
