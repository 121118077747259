import React from "react"
import styled from "styled-components"
// Libraries
import { Trans } from "gatsby-plugin-react-i18next"

// Components
import Section from "components/case-study-section/"

// Images
import UxUiProductDesignDesktop from "assets/images/case-studies/arena/ux-ui-product-design-desktop.inline.svg"
import UxUiProductDesignMobile from "assets/images/case-studies/arena/ux-ui-product-design-mobile.inline.svg"

// Utils
import breakpoints from "utils/breakpoints"
import { colors } from "utils/variables"

const StyledUxUiProductDesign = styled(Section)`
  background-color: ${colors.cream};

  .image-wrapper {
    margin: 124px 0 56px 0;

    ${breakpoints.small`
    margin: 124px 110px 0 110px;
    `}
  }
`

const UxUiProductDesign = () => (
  <StyledUxUiProductDesign>
    <div className="container justify-content-end">
      <div className="section__content">
        <p className="title color--midnight">
          <Trans>3. UX/UI Product Design</Trans>
        </p>
        <p className="color--midnight">
          <Trans>
            After some research, benchmarking, and closely studying the product
            briefs for Arena’s client- and candidate- facing products, we built
            wireframes for each alongside our partners at Aerolab.
          </Trans>
        </p>
        <br />
        <p className="color--midnight">
          <Trans>
            The priority for the candidate-facing product was creating a short,
            simple user journey, while the goal of the client-facing product was
            to make it quick and easy to search and filter candidates. Once
            wireframes were completed and confirmed with the team, we applied
            the sleek new UI that was created in the visual branding phase.
          </Trans>
        </p>
      </div>
    </div>

    <div className="image-wrapper d-flex justify-content-center">
      <UxUiProductDesignDesktop className="d-none d-sm-block" />
      <UxUiProductDesignMobile className="d-block d-sm-none" />
    </div>
  </StyledUxUiProductDesign>
)

export default UxUiProductDesign
