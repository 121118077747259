import React from "react"

// Libraries
import styled from "styled-components"
import { useStaticQuery, graphql } from "gatsby"
import Img from "gatsby-image"
import { Trans } from "gatsby-plugin-react-i18next"

// Utils
import breakpoint from "utils/breakpoints/"

// Components
import Section from "components/case-study-section/"

const StyledSection = styled(Section)`
  padding-bottom: 0 !important;

  .section__content {
    margin: 0 auto;
  }
  .image-section {
    margin-top: 56px;
    padding: 0 16px;

    ${breakpoint.medium`
      margin-top: 160px;
    `}

    .image-wrapper {
      margin: 0 auto;
      filter: drop-shadow(
        1.53571px 12.2857px 30.7143px rgba(110, 200, 205, 0.2)
      );

      &--mobile {
        filter: drop-shadow(
          1.53571px 12.2857px 30.7143px rgba(110, 200, 205, 0.2)
        );
        ${breakpoint.small`
        display: none!important;
      `}
      }

      &--desktop {
        display: none !important;

        ${breakpoint.small`
        display: block !important;
      `}
      }
    }
  }
`

const SalesDemoDesign = () => {
  const {
    imageMobile,
    imageDesktop,
    imageMobile_2,
    imageMobile_3,
  } = useStaticQuery(graphql`
    query {
      imageMobile: file(
        relativePath: {
          eq: "case-studies/arena/arena-cs-sales-demo-image-1.png"
        }
      ) {
        childImageSharp {
          fluid(maxWidth: 560, quality: 100) {
            ...GatsbyImageSharpFluid_withWebp
          }
        }
      }
      imageMobile_2: file(
        relativePath: {
          eq: "case-studies/arena/arena-cs-sales-demo-mobile-2.png"
        }
      ) {
        childImageSharp {
          fluid(maxWidth: 560, quality: 100) {
            ...GatsbyImageSharpFluid_withWebp
          }
        }
      }
      imageMobile_3: file(
        relativePath: {
          eq: "case-studies/arena/arena-cs-sales-demo-mobile-3.png"
        }
      ) {
        childImageSharp {
          fluid(maxWidth: 560, quality: 100) {
            ...GatsbyImageSharpFluid_withWebp
          }
        }
      }
      imageMobile_4: file(
        relativePath: {
          eq: "case-studies/arena/arena-cs-sales-demo-mobile-4.png"
        }
      ) {
        childImageSharp {
          fluid(maxWidth: 560, quality: 100) {
            ...GatsbyImageSharpFluid_withWebp
          }
        }
      }
      imageDesktop: file(
        relativePath: {
          eq: "case-studies/arena/cs-arena-sales-demo-image-desktop.png"
        }
      ) {
        childImageSharp {
          fluid(maxWidth: 1440, quality: 100) {
            ...GatsbyImageSharpFluid_withWebp
          }
        }
      }
    }
  `)
  return (
    <StyledSection>
      <div className="container">
        <div className="section__content">
          <p className="title mb-4">
            <Trans>4. Sales Demo Design</Trans>
          </p>
          <p>
            <Trans>
              One of Arena’s perks is that clients don’t necessarily have to
              familiarize themselves with a new tool–There was also a way to
              incorporate Arena predictions in clients’ existing Applicant
              Tracking Systems (ATS). The Arena Sales team was eager to show
              this option to potential clients, but wasn’t able to show real
              client ATSs for privacy reasons. So, we made them something even
              better.
            </Trans>
            <br />
            <br />
            <Trans>
              Our Sales Demos showed the true “Arena experience” for both
              clients and candidates in the form of an interactive Figma
              prototype. Finally, we helped Sales team get Figma-savvy so that
              they could use these demos in meetings to WOW potential clients.
            </Trans>
          </p>
        </div>
      </div>

      <div className="image-section">
        <div>
          <Img
            className="image-wrapper image-wrapper--mobile mb-4"
            fluid={imageMobile.childImageSharp.fluid}
            style={{ width: "100%", display: "block" }}
            alt="STYLE TILE EXPLORATIONS"
          />
          <Img
            className="image-wrapper image-wrapper--mobile mb-4"
            fluid={imageMobile_2.childImageSharp.fluid}
            style={{ width: "100%", display: "block" }}
            alt="STYLE TILE EXPLORATIONS"
          />
          <Img
            className="image-wrapper image-wrapper--mobile mb-4"
            fluid={imageMobile_3.childImageSharp.fluid}
            style={{ width: "100%", display: "block" }}
            alt="STYLE TILE EXPLORATIONS"
          />
        </div>

        <Img
          className="image-wrapper image-wrapper--desktop"
          fluid={imageDesktop.childImageSharp.fluid}
          style={{
            maxWidth: "1440px",
            width: "100%",
            display: "block",
          }}
          alt="STYLE TILE EXPLORATIONS"
        />
      </div>
    </StyledSection>
  )
}

export default SalesDemoDesign
